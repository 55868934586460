<template>
  <div>
 <router-view />
  </div>
</template>

<script>

// import Dashboard from '@/components/dashboard/dashboard.vue'
// import Header from '@/components/header'
// import Menu from '@/components/menu'
// import Footer from '@/components/footer'
export default {
  name:"App",
  components:{
 
  },
  data(){
    return{
    }
  },
   methods: {
 
 }

}
</script>

<style>
.conteneur-alarm{
  position:relative;
}
.badge-alarm{
  position:absolute;
  top:0;
  right: 1.5em;
  background: rgb(226, 1, 1);
  padding:.2em;
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: bold;
  border-radius:100%;
}
.bi-eye,.bi-eye-slash{
  top:20%;
  right:.5em;
  cursor: pointer;
}
table,.dataTables_wrapper{
  overflow-x: auto;
}
.vs__dropdown-toggle{
  border:1px solid black !important;
  background-color:white !important;
}

td{
  text-transform:capitalize;
}
.form-group{
  text-align:left !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
[disabled]{
  color:rgb(11, 10, 10) !important;
  font-weight: bold !important;
}
 tr:nth-child(even){
        background-color: #f2f2f2;
}



</style>

<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>
  <div class="confirmation_modifie text-dark" v-if="windowForSendRapport">
    <div class="card">
      <div class="form-body">
        <form  @submit.prevent="send_rapport">
       
        <div class="card-body">
          <h1>Envoyer le rapport</h1>
          <h3>Package : {{this.package.libelle}}</h3>         
          <div class="row pt-3">

              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label fw-bold">Nombre total de pièces</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="le nombre total de pièces vendus (ex:500)"
                    v-model="nombreTotalDePiece"
                    required
                    pattern="^([0-9]*)$"
                  />
                </div>
              </div>
            <!--/span-->
          </div>
        </div>      
          <div class="card-body">
            <button
              type="submit"
              class="btn text-white mx-3 btn-envoyer"
             :disabled="
                type_id === null
              ">
              Envoyer
            </button>
            <button @click="windowForSendRapport = !windowForSendRapport" type="button" class="btn btn-dark">
              Annuler
            </button>
        </div>
        </form>
      </div>
    </div>
  </div>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span>Mes packages souscrits</span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-fluid">
      <div class="col-md-12">
        <div class="table-responsive">
          <table id="MyTableData" class="table" >
            <thead>
              <tr>
                <th class="bg-light">#</th>
                <th class="bg-light">Nom de l'article</th>
                <th class="bg-light">Prix d'achat par pièce (Fcfa)</th>
                <th class="bg-light">Prix de vente par pièces (Fcfa)</th>
                <th class="bg-light">Gain par pièce</th>
                <th class="bg-light">Délai d'ecoulement (jours)</th>
                <th class="bg-light">Nombre de pièces</th>
                <th class="bg-light">Pièces restante</th>
                <!-- <th class="bg-light">Etat</th> -->
                <!-- <th class="bg-light text-right">Date d'enregistrement</th> -->
                <!-- <th class="bg-light" v-if="user.franchise">Détail</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in articles" :key="index">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ item.libelle }}
                </td>
                <td>{{ moneyFormat.format(item.cout_acquisition) }} Fcfa</td>
                <td>{{ moneyFormat.format(item.cout_vente) }} Fcfa</td>
                <td>{{ moneyFormat.format(item.gain_par_piece) }} Fcfa</td>
                <td>
                  <span v-if="item.franchise === 0">{{ item.nb_jours }}</span>
                  <span class="badge bg-danger" v-else>x</span> </td>
                <td>{{ item.nb_products }}</td>
                <td>{{ item.pieces_restantes }}</td>
                <!-- <td>
                  <span
                    v-if="item.etat == 'en cours de traitement'"
                    class="badge bg-dark bg-gradient"
                    >En cours de traitement</span
                  >
                  <span
                    v-if="item.etat == 'publie'"
                    class="badge bg-success bg-gradient"
                    >Publié</span
                  >
                  <span
                    v-if="item.etat == 'rejete'"
                    class="badge bg-danger bg-gradient"
                  >
                    Rejeter</span
                  >
                </td> -->
                <!-- <td>
                  {{ new Date(item.created_at).toLocaleDateString("fr") }}
                </td> -->

                <!-- <td v-if="user.franchise">
                  <button class="bg-warning" @click="showWindowForSendRapport(item.id)">Rapport</button>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { lien } from "/src/assets/api.js";
import axios from "axios";

export default {
  name: "MesPackageSouscris",
  components: {
  
    Loading,
  },
  data() {
    return {
     
      user: this.$store.state.user,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      isLoading: false,
      articles: null,
      type_id:"",
      nombreTotalDePiece:"",
      idPackage:"",
      package:"",
      windowForSendRapport:false,
      fullPage:"",
      onCancel:"",
    };
  },
  methods: {
    send_rapport(){
      this.spinner = true
    
    axios.post(lien+"/api/ventes",{
        nb_ventes:this.nombreTotalDePiece,
        package_id:this.idPackage
    })
       .then(reponse =>{
         //console.log("sendRapport",reponse.data.data);
         //console.log(reponse);
        if(reponse.data.status == 'true'){
             Swal.fire({
              text: "Rapport envoyé",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            })
            this.nb_vente =" "
            this.type_id =" "
         
          this.spinner = false
          this.windowForSendRapport = !this.windowForSendRapport
        }
        if(reponse.data.status === false){
          Swal.fire({
              text: reponse.data.message,
              icon: "info",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            })
            this.spinner = false
        }
    })
    .catch(error=>{
        console.log(error);
           Swal.fire({
              text: "Contactez votre service informatique",
              icon: "info",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
            this.spinner = false
    })
    },
    showWindowForSendRapport(id){
      this.articles.forEach(item=>{
        if(item.id == id){
          this.package = item
        }
      })
      this.idPackage = id
      //console.log("this.idPackage",this.idPackage)
      this.windowForSendRapport = !this.windowForSendRapport
    },
  },
  created() {
    this.isLoading = true;
    axios
      .get(lien + "/api/souscris/" + this.user.id, {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })

      .then((res) => {
        //console.log("OBTENIRPACKAGES", res);
        this.articles = res.data.data;
        //console.log("LIST", this.articles);
        this.isLoading = false;
        setTimeout(function () {
          $("#MyTableData").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              search: "Chercher :",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
      });
  },
};
</script>
<style scoped>
.btn-envoyer{
  background: rgb(231, 202, 15) !important;
  color:black !important;
  border:3px solid black !important;
  }
  input,select{ 
    border: 1px solid black !important;
  }
  .form-group{ 
    text-align: left !important;
  }
  .confirmation_modifie {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.255);
    display: flex;
    justify-content: center;
    place-items: center;
  }
  .confirmation_modifie .card{
    width:70%;
    padding:1em 0;
  }
.my_footer {
  position: absolute;
  width: 100%;
  bottom: -10em;
  margin-left: 0 !important;
}
.table {
  border: thin solid rgba(139, 139, 139, 0.63) !important;
}
th,
td {
  border: thin solid rgba(141, 140, 140, 0.692) !important;
}
input,
select {
  border: 1px solid black !important;
}
.form-group {
  text-align: left !important;
}
</style>

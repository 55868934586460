<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span>Création d'un utilisateur</span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="spinnerWindow" v-show="spinner">
      <div class="spinner" >
        <div class="spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
     </section>
    
    
    <div class="form-body">
      <div class="card-body">
        <div class="row pt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Nom</label>
              <input
                type="text"
                
                class="form-control"
                placeholder="Nom"
                v-model="nom"
              />
            </div>
          </div>
          <!--/span-->
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Prénoms</label>
              <input
                type="text"
                class="form-control form-control-danger"
                placeholder="Prénoms"
                v-model="prenoms"
              />
            </div>
          </div>
          <!--/span-->
        </div>
        <!--/row-->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Télephone</label>
              <input
                type="text"
                class="form-control"
                placeholder="0704565676"
                pattern="[0-9]{10}"
                required
                v-model="phone"
              />
              <span v-if="phone.length > 10 &&!phone.match(/^[0-9]{10}$/)" class="text-danger">Veuillez rentrer 10 chiffre</span>
            </div>
          </div>
          <!--/span-->
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">email</label>
              <input
                type="email"
                class="form-control"
                placeholder="ex:adjobi23@gmail.com"
                required
                v-model="email"
              />
            </div>
          </div>
          <!--/span-->
        </div>
        <!--/row-->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">lieu d'habitation</label>
              <input
                type="text"
                class="form-control"
                placeholder="ex:yopougon"
                v-model="lieu_habitation"
                 required
              />
            </div>
          </div>
          <!--/span-->
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Mot de passe</label>
              <input
                type="password"
                class="form-control"
                placeholder="ex:xxxxxx"
                v-model="password"
                 required
              />
            </div>
          </div>
         <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Assigner un role</label>
                <v-select class="w-100" v-model="role_id" :searchable="true"
                :options="listRole" placeholder="choisir un role" multiple label="libelle">
                   <template>
                     <p>{{label}}</p>
                   </template>
                 </v-select>
               
              </div>
            </div>
            
             <div class="col-md-6"  v-if="role_id.some(item => item.libelle === 'fournisseur')">
            <div class="form-group">
              <label class="form-label">Registre de commerce</label>
              <input
                type="text"
                class="form-control"
                placeholder="ex:17373HDD73HSHS"
                v-model="registre"
              />
            </div>
          </div>

          <div class="col-md-6"
          v-if="role_id.some(item => item.libelle === 'investisseur')">
            <div class="form-group">
              <label class="form-label">Solde</label>
              <input
                type="number"
                class="form-control"
                placeholder="ex:15 000 000"
                v-model="soldeUser"
              />
            </div>
          </div>
          <!--/span-->
        </div>
        <div class="row">
          <div class="col-md-6" >
            <div class="form-group">
              <label class="form-label">Numéro de compte bancaire</label>
              <input
              type="text"
              class="form-control"
              placeholder="xfd5552sd"
              v-model="numberCompteBancaire"
              required
            />
            </div>
          </div>
    
          
         <div class="col-md-6">
              <div class="form-group">
                <label class="form-label d-block">Pièce jointe</label>
                
              <input type="file" @change.prevent="handleMedias">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label d-block">Photo de profil</label>
               
              <input type="file" @change.prevent="handleMediasPhotoProfil">
              </div>
            </div>
           
          <!--/span-->
        </div>
        <!--/row-->
      </div>
      <div class="form-actions">
        <div class="card-body">
          <button
            type="submit"
            @click.prevent="poster_user"
            :disabled="
              nom === null &&
              prenoms === null &&
              email === null &&
              password === null &&
              telephone === null
            "
            class="btn btn-lg mx-2 btn-user text-white"
          >
            Créer un utilisateur
          </button>
          <button type="button" class="btn btn-dark btn-lg">Annuler</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Swal from "sweetalert2";

import 'vue-select/dist/vue-select.css';
import axios from "axios";
import { lien } from "/src/assets/api.js";
export default {
  name: "User",

  //+++++ DATA ++++//
  data() {
    return {
      numberCompteBancaire:"",
      selectedOption:'0',
      soldeUser:0,
      nom: null,
      prenoms: null,
      phone:"",
      email: null,
      lieu_habitation: null,
      password: null,
      listRole:[],
      role_id:[],
      showCreated:false,
      role:null,
      registre:null,
      telephone:"",
      spinner:false,
      proofPieces:"",
      pictureProfil:"",
      listRoleFilter:[],
    };
  },

  //+++++ METHODES ++++//
  methods: {
    boucleTableau(tableau){
     let tableauRole = []
      tableau.forEach(item=>{
        tableauRole.push(item.id)
    })
    return tableauRole
    },
    
    handleMediasPhotoProfil(files) {

      this.pictureProfil = files.target.files[0];
   
    },
    handleMedias(files) {
      
      this.proofPieces = files.target.files[0];

    },
    poster_user() {
      this.spinner = true
      let tableauRole = this.boucleTableau(this.role_id)
      // console.log("tableauRole",this.role_id);
      let data = new FormData();
      for (let i = 0; i < tableauRole.length; i++) {
        data.append("role_id[]",tableauRole[i]);
        }
      data.append("nom", this.nom);
      data.append('solde',this.soldeUser)
      // data.append('franchise',this.selectedOption);
      data.append("prenoms", this.prenoms);
      data.append("phone", this.phone);
      data.append("email", this.email);
      data.append("lieu_habitation", this.lieu_habitation);
      data.append("password",this.password);
     
      data.append("registre_commerce", this.registre);
      data.append("identity", this.proofPieces);
      data.append("bank_card", this.numberCompteBancaire);
      data.append("picture", this.pictureProfil);

      axios.post(lien + "/api/users", data)
        .then((reponse) => {
      
          if (reponse.data.status == "true") {
            this.spinner = false
            Swal.fire({
              text: "Utilisateur crée",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
           setTimeout(()=>{
              this.$router.push("/listusers")
           },1500)
          }
          if(reponse.data.status == "false") {
            this.spinner = false
            Swal.fire({
              text: "Veuillez remplir tous les champs",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
              text: "Veuillez remplir tous les champs",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.spinner = false
        });
    },
    created_role_show(){
     this.showCreated = !this.showCreated
    },
      get_role(){
  axios.get(lien + "/api/roles")
      .then((reponse) => {
        //console.log("ROLES:", reponse.data.data);
        this.listRole = reponse.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },


    creation_role(){
     axios.post(lien +"/api/roles",{
     libelle: this.role
    })
      .then((reponse) => {
        //console.log("ROLES:", reponse.data.data);
        if(reponse){
       Swal.fire({
              text:"creation de role effectué",
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
        }
        location.reload(true);
      })
      .catch((error) => {
        console.log(error);
      });

    }
  },

  created() {
    this.get_role()
  }
};
</script>
<style scoped>
.spinner{
  background-color:rgb(27, 27, 27) !important;
  width:45px;
  height:45px;
  padding:.5em 0 0 0 ;
  border-radius:5px;
}
.spinnerWindow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 101, 101, 0.295);
  z-index: 99;
  display: flex;
  place-items: center;
  justify-content: center;
}
.my_footer{
position:relative;
width:100%;
bottom:-7em;
margin-left: 0 !important;
}
select {
  padding: 0.589em !important;

  border-radius: 0.25rem;
}
select:active {
  border: 1px solid rgb(134, 134, 134);
}
.bouton_create-role{
text-align: left;
margin-left:1.5em;
}
.bouton-annuler{
background: rgb(231, 202, 15) !important;
border:1px solid white !important;
font-weight: bold;
}
.btn-valider,.btn-user{
background: rgb(231, 202, 15) !important;
border:3px solid rgb(0, 0, 0) !important;
color:black !important;
}
.btn-creer-role{
background: rgb(231, 202, 15) !important;
border:3px solid rgb(0, 0, 0) !important;
color:black !important;
}
.created_role{
position:fixed;
top:0;
left: 0;
width:100%;
height:100%;
background: rgba(0, 0, 0, 0.405);
display:flex;
place-items: center;
justify-content: center;
}
.from_create_role{
background: rgb(253, 251, 251);
padding: 5em;
margin-left:10%;
}
input,select{ 
  border: 1px solid black !important;
}
.form-group{ 
  text-align: left !important;
}
label{
  font-weight:bold !important;
}
</style>

<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>
  <div class="page-wrapper">
    <div class="container">
      <div class="row g-0">
        <div class="div_hello position-relative">
          <div>
            <h1 class="float-right w-50 p-0 m-0 fw-bold" v-if="user !== null">
              Hello , {{ user.nom }} {{ user.prenoms }}
            </h1>
            <h4 class="my-2" v-if="jour !== null">{{ jour }}</h4>
          </div>
          <img
            v-if="this.seeNombre"
            class="position-absolute w-25 img-trader"
            src="/assets/images/Stuck.png"
            alt="icon"
          />
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-lg-3 col-md-6" v-if="this.seeNombre">
            <div class="card border">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="bi bi-box"></i></h3>
                        <p>PACKAGES</p>
                      </div>
                      <div class="ms-auto">
                        <!-- <span class="badge bg-danger" v-show="newshow">new</span> -->
                        <span
                          class="counter text-primary h2"
                          v-if="this.nombrePackages !== null"
                        >
                          {{ nombrePackages }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6" v-if="see_nombre_package">
            <div class="card border">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="bi bi-box"></i></h3>
                        <p>PACKAGES</p>
                      </div>
                      <div class="ms-auto">
                        <h2
                          class="counter text-primary"
                          v-if="this.number_nombre_package_fournisseur !== null"
                        >
                          {{ this.number_nombre_package_fournisseur }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6" v-if="this.seeNombre">
            <div class="card border">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="bi bi-box-seam"></i></h3>
                        <p>CATEGORIES</p>
                      </div>
                      <div class="ms-auto">
                        <h2
                          class="counter text-cyan"
                          v-if="this.nombreTypePackages !== null"
                        >
                          {{ nombreTypePackages }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6" v-if="this.seeNombre">
            <div class="card border">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                      <div>
                        <h3><i class="bi bi-people"></i></h3>
                        <p>FOURNISSEURS</p>
                      </div>
                      <div class="ms-auto">
                        <h2
                          class="counter text-purple"
                          v-if="this.nombreFournisseurs !== null"
                        >
                          {{ nombreFournisseurs }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div class="conteneur_filter" v-if="this.user.tab.includes('admin')">
      <section class="filter_fournisseur col-lg-6 col-sm-12">
        <div class="spinner" v-show="spinnerFournisseur">
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <h5>Fournisseur</h5>
        <select v-model="choosePeriodeForFiltreStatistique">
            <option value="" disabled>Choisir une période</option>
            <option value="day">Jour</option>
            <option value="week">Semaine</option>
            <option value="month">Mois</option>
            <option value="year">Année</option>
          </select>
          <div>
            <input
              type="month"
              class="w-100 mt-2"
              v-if="choosePeriodeForFiltreStatistique == 'month'"
              v-model="mois"
            />
            <input
              type="date"
              class="w-100 mt-2"
              v-if="choosePeriodeForFiltreStatistique == 'day'"
              v-model="dayJour"
            />
            <input
              type="week"
              class="w-100 mt-2"
              v-if="choosePeriodeForFiltreStatistique == 'week'"
              v-model="week"
              @change="getDayOfWeek"
            />
            <input
              type="year"
              class="w-100 mt-2"
              v-if="choosePeriodeForFiltreStatistique == 'year'"
              v-model="year"
            />
            <button
              class="btn-lg bg-warning mt-2 mx-2"
              v-if="choosePeriodeForFiltreStatistique"
              @click.prevent="sendDataForgetStatistiqueFournisseur"
            >
              Filtrer
            </button>
          </div>
  
          <Responsive class="w-full">
            <template #main="{ width }">
              <Chart
                :size="{ width, height: 300 }"
                :data="dataOfStatistique"
                :margin="margin"
                :direction="direction"
                :axis="axis"
              >
                <template #layers>
                  <Grid strokeDasharray="2,2"/>
                  <Line
                    :dataKeys="['name', 'number']"
                    type="monotone"
                    :areaStyle="{ fill: 'url(#grad)' }"
                  />
                  <LabelsLayer :dataKeys="['name', 'number']" />
                  
                </template>
    
                <template #widgets>
                  <Tooltip borderColor="#48CAE4" :config="tooltipConfig" />
                </template>
              </Chart>
            </template>
          </Responsive>
        </section>
        <section class="filter_investisseur col-lg-6 col-sm-12">
          <div class="spinner" v-show="spinnerInvestisseur">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <h5>Investisseur</h5>
          <select v-model="choosePeriodeForFiltreStatistiqueInvestisseur">
              <option value="" disabled>Choisir une période</option>
              <option value="day">Jour</option>
              <option value="week">Semaine</option>
              <option value="month">Mois</option>
              <option value="year">Année</option>
            </select>
            <div>
              <input
                type="month"
                class="w-100 mt-2"
                v-if="choosePeriodeForFiltreStatistiqueInvestisseur == 'month'"
                v-model="moisInvestisseur"
              />
              <input
                type="date"
                class="w-100 mt-2"
                v-if="choosePeriodeForFiltreStatistiqueInvestisseur == 'day'"
                v-model="dayJourInvestisseur"
              />
              <input
                type="week"
                class="w-100 mt-2"
                v-if="choosePeriodeForFiltreStatistiqueInvestisseur == 'week'"
                v-model="weekInvestisseur"
                @change="getDayOfWeek"
              />
              <input
                type="year"
                class="w-100 mt-2"
                v-if="choosePeriodeForFiltreStatistiqueInvestisseur == 'year'"
                v-model="yearInvestisseur"
              />
              <button
                class="btn-lg bg-warning mt-2 mx-2"
                v-if="choosePeriodeForFiltreStatistiqueInvestisseur"
                @click.prevent="sendDataForStatistiqueInvestisseur"
              >
                Filtrer
              </button>
            </div>
    
            <Responsive class="w-full">
              <template #main="{ width }">
                <Chart
                  :size="{ width, height: 300 }"
                  :data="dataOfStatistiqueInvestisseur"
                  :margin="margin"
                  :direction="direction"
                  :axis="axis"
                >
                  <template #layers>
                    <Grid strokeDasharray="2,2"/>
                    <Line
                      :dataKeys="['name', 'number']"
                      type="monotone"
                      :areaStyle="{ fill: 'url(#grad)' }"
                    />
                    <LabelsLayer :dataKeys="['name', 'number']" />
                    
                  </template>
      
                  <template #widgets>
                    <Tooltip borderColor="#48CAE4" :config="tooltipConfigInvestisseur" />
                  </template>
                </Chart>
              </template>
            </Responsive>
          </section>
          <section class="filter_chiffre_affaire col-lg-6 col-sm-12">
            <div class="spinner" v-show="spinnerChiffreAffaire">
              <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <h5>Chiffre d'affaire</h5>
            <select v-model="choosePeriodeForFiltreStatistiqueChiffreAffaire">
                <option value="" disabled>Choisir une période</option>
                <option value="day">Jour</option>
                <option value="week">Semaine</option>
                <option value="month">Mois</option>
                <option value="year">Année</option>
              </select>
              <div>
                <input
                  type="month"
                  class="w-100 mt-2"
                  v-if="choosePeriodeForFiltreStatistiqueChiffreAffaire == 'month'"
                  v-model="moisChiffreAffaire"
                />
                <input
                  type="date"
                  class="w-100 mt-2"
                  v-if="choosePeriodeForFiltreStatistiqueChiffreAffaire == 'day'"
                  v-model="dayJourChiffreAffaire"
                />
                <input
                  type="week"
                  class="w-100 mt-2"
                  v-if="choosePeriodeForFiltreStatistiqueChiffreAffaire == 'week'"
                  v-model="weekChiffreAffaire"
                  @change="getDayOfWeek"
                />
                <input
                  type="year"
                  class="w-100 mt-2"
                  v-if="choosePeriodeForFiltreStatistiqueChiffreAffaire == 'year'"
                  v-model="yearChiffreAffaire"
                />
                <button
                  class="btn-lg bg-warning mt-2 mx-2"
                  v-if="choosePeriodeForFiltreStatistiqueChiffreAffaire"
                  @click.prevent="sendDataForgetStatistiqueChiffreAffaire"
                >
                  Filtrer
                </button>
              </div>
      
              <Responsive class="w-full">
                <template #main="{ width }">
                  <Chart
                    :size="{ width, height: 300 }"
                    :data="dataOfStatistiqueChiffreAffaire"
                    :margin="margin"
                    :direction="direction"
                    :axis="axis"
                  >
                    <template #layers>
                      <Grid strokeDasharray="2,2"/>
                      <Line
                        :dataKeys="['name', 'number']"
                        type="monotone"
                        :areaStyle="{ fill: 'url(#grad)' }"
                      />
                      <LabelsLayer :dataKeys="['name', 'number']" />
                      
                    </template>
        
                    <template #widgets>
                      <Tooltip borderColor="#48CAE4" :config="{
                        name: { hide: true},
                        number: {label: 'Chiffre d\'affaire',color: '#0077b6' },
                      }" />
                    </template>
                  </Chart>
                </template>
              </Responsive>
            </section>
     </div>
     

     
    </div>
  </div>
</template>
<script>
import { Chart, Grid, Line, Tooltip, Responsive } from "vue3-charts";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "boxicons";
import { lien } from "/src/assets/api.js";
import axios from "axios";
export default {
  name: "Acceuil",
  components: {
    Loading,
    Chart,
    Grid,
    Line,
    Tooltip,
    Responsive,
  },

  data() {
    return {
      spinnerFournisseur:false,
      spinnerInvestisseur:false,
      spinnerChiffreAffaire:false,
      onCancel: "",
      fullPage: "",
      week: "",
      jour: "",
      packages:"",
      moisInvestisseur:"",
      dayJourInvestisseur:"",
      weekInvestisseur:"",
      yearInvestisseur:"",
      moisChiffreAffaire:"",
      dayJourChiffreAffaire:"",
      weekChiffreAffaire:"",
      yearChiffreAffaire:"",
      choosePeriodeForFiltreStatistique: "",
      choosePeriodeForFiltreStatistiqueInvestisseur:"",
      dataOfStatistique: [
        { name: "", number: 0 }
      ],
      choosePeriodeForFiltreStatistiqueChiffreAffaire:"",
      dataOfStatistiqueInvestisseur:[{name:"",number:0}],
      dataOfStatistiqueChiffreAffaire:[{name:"",number:0}],
      axis: {
        primary: {
          type: "band",
        },
        secondary: {
          domain: ["dataMin", "dataMax"],
          type: "linear",
          ticks: 8,
        },
      },
      direction: "horizontal",
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
      },
      user: null,
      margin: {
        left: 0,
        top: 20,
        right: 20,
        bottom: 0,
      },
      tooltipConfig: {
        name: { hide: true},
        number: {label: "Fournisseur",color: "#0077b6" },
      },
      tooltipConfigInvestisseur:{
        name: { hide: true},
        number: {label: "Investisseur",color: "#0077b6" },
      },
      newshow: false,
      isLoading: false,
      date_jour: new Date(),
      list_packages: null,
      nombrePackages: null,
      newNombrePackage: null,
      nombreTypePackages: null,
      nombreFournisseurs: null,
      seeNombre: true,
      see_nombre_package: true,
      nombre_package_fournisseur: null,

      dayJour: "",

      mois: "",

      year: "",

      weekFrom: "",
      weekTo: "",
    };
  },

  methods: {
    getDayOfWeek(e) {
      //console.log(e.target.value);
      let dateWeek = e.target.value;
      let year = parseInt(dateWeek.slice(0, 4), 10);
      let week = parseInt(dateWeek.slice(6), 10);
      let day = 1 + (week - 1) * 7;
      let dayOffset = new Date(year, 0, 1).getDay();
      dayOffset--;
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push(
          new Date(year, 0, day - dayOffset + i).toISOString().substring(0, 10)
        );
      }
      this.weekFrom = days[0];
      this.weekTo = days[days.length - 1];
      //console.log("debutweek", this.weekFrom);
      //console.log("finweek", this.weekTo);
    },
    get_Nombre() {
      if (this.user.tab.includes("admin")) {
        this.seeNombre = true;
        this.see_nombre_package = false;
        this.getNumberPackages();
        this.getData();
        this.getFournisseurs();
      }
      if (this.user.tab.includes("fournisseur")) {
        this.seeNombre = false;
        this.see_nombre_package = true;
      }
    },
    getNumberPackages() {
      this.isLoading = true;
      axios
        .get(lien + "/api/packages", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((res) => {
          //console.log("OBTENIRPACKAGES", res.data.data);
          this.list_packages = res.data.data;

          this.nombrePackages = this.list_packages.length;
          localStorage.setItem("nombre", this.nombrePackages);
          //console.log("NOTIFICATION", this.newNombrePackage);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      axios
        .get(lien + "/api/types", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((res) => {
          //console.log("OBTENIRTYPESPACKAGES", res);
          this.listTypesPackages = res.data.data;
          this.nombreTypePackages = this.listTypesPackages.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFournisseurs() {
      axios
        .get(lien + "/api/fournisseurs", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((res) => {
          //console.log("OBTENIFOURNISSEURS", res);
          this.list_fournisseurs = res.data.data;
          this.nombreFournisseurs = this.list_fournisseurs.length;
          //console.log("LISTFOURNISSEURS", this.list_fournisseurs);
        });
    },
    getNombreFournisseur() {
      this.isLoading = true;
      axios
        .get(lien + "/api/fourn_package/" + this.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })

        .then((res) => {
         
          this.packages = res.data.data;
          
          this.number_nombre_package_fournisseur = res.data.status === false ? null:this.packages.length;
      
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async launchStatistiqueFournisseur(data, route) {
  this.dataOfStatistique = [];
  this.spinnerFournisseur = true;
  
  try {
    const response = await axios.post(lien + route, data, {
      headers: {
        Authorization: "Bearer " + this.$store.state.token,
      },
    });
    
    //console.log("DATAFILTER", response.data.data);
    
    if (data.annee) {
      const transformedData = Object.entries(response.data.data).map(([key, value]) => {
        const date = new Date(key);
        const month = date.toLocaleDateString("fr-FR", { month: "short" });
        return {
          name: month,
          number: value,
        };
      });
     
      this.dataOfStatistique = Object.keys(response.data.data).length > 0 || response.data.data.length ? transformedData : [{ name: "", number: 0 }];
      //console.log("this.dataOfStatistique", this.dataOfStatistique);
    } else if (data.mois || data.semaine) {
      const transformedData = Object.entries(response.data.data).map(([key, value]) => {
        const date = new Date(key);
        const day = date.toLocaleDateString("fr-FR", { day: "short" });
        return {
          name: day,
          number: value,
        };
      });
      this.dataOfStatistique = Object.keys(response.data.data).length > 0 || response.data.data.length ? transformedData : [{ name: "", number: 0 }];
    } else if (data.jour) {
      const transformedData = Object.entries(response.data.data).map(([key, value]) => {
        return {
          name: key,
          number: value,
        };
      });
      this.dataOfStatistique = Object.keys(response.data.data).length > 0 || response.data.data.length ? transformedData : [{ name: "", number: 0 }];
    } else {
      return;
    }
    
    this.spinnerFournisseur = false;
  } catch (err) {
    console.log(err);
    this.spinnerFournisseur = false;
  }
},
    launchStatistiqueInvestisseur(data,route) {
      this.dataOfStatistiqueInvestisseur = []
      this.spinnerInvestisseur = true
      axios
        .post(lien + route, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("DATAFILTER", response.data.data);
          if (data.annee) {
            const transformedData = Object.entries(response.data.data).map(
              ([key, value]) => {
                const date = new Date(key);
                const month = date.toLocaleDateString("fr-FR", {
                  month: "short",
                });
                return {
                  name: month,
                  number: value,
                };
              }
            );
           
            this.dataOfStatistiqueInvestisseur  =  Object.keys(response.data.data).length > 0 || response.data.data.length  ? transformedData : [{name:"",number:0}]
            //console.log("this.dataOfStatistiqueInvestisseur", this.dataOfStatistiqueInvestisseur);
          } else if (data.mois || data.semaine) {
            const transformedData = Object.entries(response.data.data).map(
              ([key, value]) => {
                const date = new Date(key);
                const day = date.toLocaleDateString("fr-FR", {
                  day: "short",
                });

                return {
                  name: day,
                  number: value,
                };
              }
            );
            this.dataOfStatistiqueInvestisseur = Object.keys(response.data.data).length > 0 || response.data.data.length  ? transformedData : [{name:"",number:0}]
          } else if (data.jour) {
            const transformedData = Object.entries(response.data.data).map(
              ([key, value]) => {
                return {
                  name: key,
                  number: value,
                };
              }
            );
            this.dataOfStatistiqueInvestisseur = Object.keys(response.data.data).length > 0 || response.data.data.length  ? transformedData : [{name:"",number:0}]
          } else {
            return;
          }
          this.spinnerInvestisseur = false
        })
        .catch((err) => {
          console.log(err);
          this.spinnerInvestisseur = false
        });
    },
    launchStatistiqueChiffreAffaire(data,route) {
      this.dataOfStatistiqueChiffreAffaire = []
      this.spinnerChiffreAffaire = true
      axios
        .post(lien + route, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("DATAFILTER", response.data.data);
          if (data.annee) {
            const transformedData = Object.entries(response.data.data).map(
              ([key, value]) => {
                const date = new Date(key);
                const month = date.toLocaleDateString("fr-FR", {
                  month: "short",
                });
                return {
                  name: month,
                  number: value,
                };
              }
            );
           
            this.dataOfStatistiqueChiffreAffaire  = Object.keys(response.data.data).length > 0 || response.data.data.length  ? transformedData : [{name:"",number:0}]
            //console.log("this.dataOfStatistiqueChiffreAffaire", this.dataOfStatistiqueChiffreAffaire);
          } else if (data.mois || data.semaine) {
            const transformedData = Object.entries(response.data.data).map(
              ([key, value]) => {
                const date = new Date(key);
                const day = date.toLocaleDateString("fr-FR", {
                  day: "short",
                });

                return {
                  name: day,
                  number: value,
                };
              }
            );
            this.dataOfStatistiqueChiffreAffaire = 
            Object.keys(response.data.data).length > 0 || response.data.data.length  ? transformedData : [{name:"",number:0}]
          } else if (data.jour) {
            const transformedData = Object.entries(response.data.data).map(
              ([key, value]) => {
                return {
                  name: key,
                  number: value,
                };
              }
            );
            this.dataOfStatistiqueChiffreAffaire = Object.keys(response.data.data).length > 0 || response.data.data.length  ? transformedData : [{name:"",number:0}]
          } else {
            return;
          }
          this.spinnerChiffreAffaire = false
        })
        .catch((err) => {
          console.log(err);
          this.spinnerChiffreAffaire = false
        });
    },
    sendDataForStatistiqueInvestisseur(){
      let route ="/api/stats_investors";
      
      if (this.choosePeriodeForFiltreStatistiqueInvestisseur == "year" && this.yearInvestisseur) {
        let dataYear = {
          annee: this.yearInvestisseur,
        };
        this.launchStatistiqueInvestisseur(dataYear,route);
      } else if (
        this.choosePeriodeForFiltreStatistiqueInvestisseur == "month" &&
        this.moisInvestisseur
      ) {
        let dataMonth = {
          mois: this.moisInvestisseur,
        };
        this.launchStatistiqueInvestisseur(dataMonth,route);
      } else if (
        this.choosePeriodeForFiltreStatistiqueInvestisseur == "day" &&
        this.dayJourInvestisseur
      ) {
        let dataJour = {
          jour: this.dayJourInvestisseur,
        };
        this.launchStatistiqueInvestisseur(dataJour,route);
      } else if (
        this.choosePeriodeForFiltreStatistiqueInvestisseur == "week" &&
        this.weekInvestisseur
      ) {
        let dataWeek = {
          from: this.weekFrom,
          to: this.weekTo,
          semaine:true,
        };
        this.launchStatistiqueInvestisseur(dataWeek,route);
      } else {
        return;
      }
    },
    async sendDataForgetStatistiqueFournisseur() {
      let route = '/api/stats_sellers';
     
      //console.log(route)
      if (this.choosePeriodeForFiltreStatistique == "year" && this.year) {
        let dataYear = {
          annee: this.year,
        };
        await this.launchStatistiqueFournisseur(dataYear,route);
      } else if (
        this.choosePeriodeForFiltreStatistique == "month" &&
        this.mois
      ) {
        let dataMonth = {
          mois: this.mois,
        };
        await this.launchStatistiqueFournisseur(dataMonth,route);
      } else if (
        this.choosePeriodeForFiltreStatistique == "day" &&
        this.dayJour
      ) {
        let dataJour = {
          jour: this.dayJour,
        };
        await this.launchStatistiqueFournisseur(dataJour,route);
      } else if (
        this.choosePeriodeForFiltreStatistique == "week" &&
        this.week
      ) {
        let dataWeek = {
          from: this.weekFrom,
          to: this.weekTo,
          semaine:true,
        };
        await this.launchStatistiqueFournisseur(dataWeek,route);
      } else {
        return;
      }
    },
    sendDataForgetStatistiqueChiffreAffaire() {
      let route = '/api/stats_benefs';
     
      //console.log(route)
      if (this.choosePeriodeForFiltreStatistiqueChiffreAffaire == "year" && this.yearChiffreAffaire) {
        let dataYear = {
          annee: this.yearChiffreAffaire,
        };
        this.launchStatistiqueChiffreAffaire(dataYear,route);
      } else if (
        this.choosePeriodeForFiltreStatistiqueChiffreAffaire == "month" &&
        this.moisChiffreAffaire
      ) {
        let dataMonth = {
          mois: this.moisChiffreAffaire,
        };
        this.launchStatistiqueChiffreAffaire(dataMonth,route);
      } else if (
        this.choosePeriodeForFiltreStatistiqueChiffreAffaire == "day" &&
        this.dayJourChiffreAffaire
      ) {
        let dataJour = {
          jour: this.dayJourChiffreAffaire,
        };
        this.launchStatistiqueChiffreAffaire(dataJour,route);
      } else if (
        this.choosePeriodeForFiltreStatistiqueChiffreAffaire == "week" &&
        this.weekChiffreAffaire
      ) {
        let dataWeek = {
          from: this.weekFrom,
          to: this.weekTo,
          semaine:true,
        };
        this.launchStatistiqueChiffreAffaire(dataWeek,route);
      } else {
        return;
      }
    },
    getStatisticForYear(){
      let dataYear = {
        annee:new Date().getFullYear()
      }
      //console.log("DATAYEAR",dataYear)
      const routeFournisseur = '/api/stats_sellers'
      const routeInvestisseur = '/api/stats_investors'
      const routeChiffreAffaire = '/api/stats_benefs'

      this.launchStatistiqueFournisseur(dataYear,routeFournisseur)
      this.launchStatistiqueInvestisseur(dataYear,routeInvestisseur)
      this.launchStatistiqueChiffreAffaire(dataYear,routeChiffreAffaire)
    }
    
  },
  created() {
    this.user = this.$store.state.user;
    this.getNombreFournisseur();
    this.get_Nombre();
    this.getStatisticForYear()
  },
};
</script>
<style scoped>
.conteneur_filter{
  display: flex;
  flex-wrap: wrap;
  align-items:center;
 justify-content: center;
}
.conteneur_filter section h5{
  font-size:1.5em;
  font-weight:bold;
  padding:.5em 0;
}
.spinner{
  position:absolute;
  left:50%;
  bottom:50%;
}
.filter_fournisseur,.filter_investisseur,.filter_chiffre_affaire{
  text-align: left;
  padding:.5em;
  background: white;
  position:relative;
  width:600px;
  margin:1em;
  border-radius:10px;
  box-shadow:1px 1px 5px rgba(0, 0, 0, 0.133);
}
.btn-lg {
  height: 40px !important;
  border: 1px solid black !important;
  border-radius: 0px !important;
}
select,
input {
  width: 250px !important;
  height: 30px !important;
  border: 1px solid black !important;
}

.div_hello {
  text-align: left;
  background: rgba(0, 0, 0, 0.888);
  color: rgb(255, 255, 255) !important;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.274) !important;
  border: 5px solid rgb(236, 182, 4) !important;
  padding: 3em !important;
  margin: 1em 0 !important;
  border-radius: 10px !important;
}
.div_hello h1 {
  font-size: 3em;
}

.card {
  border-radius: 10px !important;
  color: black !important;
  font-weight: bold;
}
.card .card-body {
  border-radius: 10px !important;
  padding: 3em !important;
  background: white;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.274) !important;
}
.page-titles {
  background: transparent;
  box-shadow: none;
}
.img-trader {
  right: 5em;
  top: -30px;
  width: 240px !important;
}
</style>

<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>
  <div class="confirmation_modifie text-dark" v-if="windowForSendRapport">
    <div class="card">
      <div class="form-body">
        <form  @submit.prevent="send_rapport">
       
        <div class="card-body">
          <h1 v-if="this.user.role.includes(item=>item.libelle == 'admin')">Approuver le rapport</h1>
          <h1 v-else>Envoyer le rapport</h1>
          <h3>Package : {{this.package.libelle}}</h3>         
          <div class="row pt-3">

              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label fw-bold">Nombre total de pièces</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="le nombre total de pièces vendus (ex:500)"
                    v-model="nombreTotalDePiece"
                    required
                    pattern="^([0-9]*)$"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label fw-bold">Pièce jointe</label>
                  <input
                    type="file"
                    class="form-control"
                    @change.prevent="handleMedias"
                  />
                </div>
              </div>
            <!--/span-->
          </div>
        </div>      
          <div class="card-body">
            <button
              type="submit"
              class="btn text-white mx-3 btn-envoyer"
             :disabled="
                type_id === null
              ">
              Envoyer
            </button>
            <button @click="windowForSendRapport = !windowForSendRapport" type="button" class="btn btn-dark">
              Annuler
            </button>
        </div>
        </form>
      </div>
    </div>
  </div>
  <div class="page-wrapper">
    <div class="container-fluid position-relative">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3">
                <span
                  > Franchises / Packages
                </span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="icon">
        <a href="javascript:void(0)" class="back h4" @click="$router.go(-1)"
          ><box-icon name="left-arrow-alt" animation="tada"></box-icon
          >Retour</a
        >
      </div>
    </div>
    <div class="row container-fluid">
      <div class="col-md-12">
        
       

        <div>
          <h1>Franchises</h1>
          <table
            id="MyTableData1"
            class="table"
          >
            <thead>
              <tr>
              
                <th class="bg-light">Franchise</th>
                <th class="bg-light">Prix achat (Fcfa)</th>
                <th class="bg-light">Prix de vente (Fcfa)</th>
                <th class="bg-light">Nombre de pièces</th>
                <th class="bg-light">Prix du package</th>
                <th class="bg-light">Détail</th>
           
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in franchise_fournisseur" :key="index">
                
                <td>
                  {{ item.libelle }}
                </td>
                <td>
                  {{ this.moneyFormat.format(item.cout_acquisition) }} Fcfa
                </td>
                <td>{{ this.moneyFormat.format(item.cout_vente) }} Fcfa</td>
                <td>
                  {{ item.nb_products }}
                </td>
                <td>
                  {{
                    this.moneyFormat.format(
                      item.cout_acquisition * item.nb_products
                    )
                  }}
                  Fcfa
                </td>
              
                <td>
                  <button class="bg-warning"
                   @click="showWindowForSendRapport(item.id)">Rapport</button>
                </td>

              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h1>Packages</h1>
          <table
            id="MyTableData"
            class="table"
          >
            <thead>
              <tr>
              
                <th class="bg-light">Packages</th>
                <th class="bg-light">Prix achat (Fcfa)</th>
                <th class="bg-light">Prix de vente (Fcfa)</th>
                <th class="bg-light">Nombre de pièces</th>
                <th class="bg-light">Prix du package</th>
                
           
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in packages_fournisseur" :key="index">
                
                <td>
                  {{ item.libelle }}
                </td>
                <td>
                  {{ this.moneyFormat.format(item.cout_acquisition) }} Fcfa
                </td>
                <td>{{ this.moneyFormat.format(item.cout_vente) }} Fcfa</td>
                <td>
                  {{ item.nb_products }}
                </td>
                <td>
                  {{
                    this.moneyFormat.format(
                      item.cout_acquisition * item.nb_products
                    )
                  }}
                  Fcfa
                </td>
              
                <!-- <td>
                  <button class="bg-warning"
                   @click="showWindowForSendRapport(item.id)">Rapport</button>
                </td> -->

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import { lien } from "/src/assets/api.js";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
export default {
  name: "Package-fournisseurs",
  components: {
    
    Loading,
  },
  data() {
    return {
      isLoading:false,
      franchiseFournisseur:[],
      packages_fournisseur: [],
      franchise_fournisseur:[],
      user: null,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      Mysolde: null,
      windowForSendRapport:false,
      nombreTotalDePiece:"",
      idPackage:"",
      package:"",
      proofPieces:"",
      fullPage: "",
    };
  },
  methods: {
    handleMedias(files) {
      // let pictureAtUpload = files
      this.proofPieces = files.target.files[0];
      //console.log("Photo de pièces", files.target.files[0]);
    },
    send_rapport(){
      this.spinner = true
    let data = new FormData();
    data.append('nb_ventes',this.nombreTotalDePiece)
    data.append('package_id',this.idPackage)
    data.append('proof',this.proofPieces)
    axios.post(lien+"/api/ventes",data)
       .then(reponse =>{
         //console.log("sendRapport",reponse.data.data);
         //console.log(reponse);
        if(reponse.data.status == 'true'){
             Swal.fire({
              text: "Rapport envoyé",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            })
            this.nb_vente =" "
            this.type_id =" "
         
          this.spinner = false
        }
        if(reponse.data.status === false){
          Swal.fire({
              text: reponse.data.message,
              icon: "info",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            })
            this.spinner = false
        }
    })
    .catch(error=>{
        console.log(error);
           Swal.fire({
              text: "Contactez votre service informatique",
              icon: "info",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
            this.spinner = false
    })
    },
    showWindowForSendRapport(id){
      this.packages_fournisseur.forEach(item=>{
        if(item.id == id){
          this.package = item
        }
      })
      this.idPackage = id
      //console.log("this.idPackage",this.idPackage)
      this.windowForSendRapport = !this.windowForSendRapport
    },
    getUser() {
      this.isLoading = true;
      axios
        .get(lien + "/api/fournisseurs")
        .then((res) => {
          //console.log("OBTENIR USER", res.data.data);
          this.user = res.data.data.find(
            (item) => item.id == this.$route.params.id
          );
          this.Mysolde = `${this.moneyFormat.format(this.user.solde)} Fcfa`;
          //console.log("User", this.user);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addDaysToDate(date, days) {
      const res = new Date(date);
      res.setDate(res.getDate() + days);
      return res;
    },
    getPackages(){
      this.isLoading = true;
    axios
      .get(lien + `/api/fourn_package/${this.$route.params.id}`)
      .then((res) => {
           let compteur = 0;
          //  console.log("res.data.data",res.data.data)
           if(res.data.status == 'true' || res.data.status === true ){
while(compteur < res.data.data.length){
                if(res.data.data[compteur].franchise === 0){
                  this.packages_fournisseur.push(res.data.data[compteur])
               
                }
                if(res.data.data[compteur].franchise === 1){
                      this.franchise_fournisseur.push(res.data.data[compteur]) 
                }
            compteur=compteur+1;
            // console.log("this.franchise_fournisseur",this.franchise_fournisseur)
           }
        this.isLoading = false;
           }
           
        setTimeout(function () {
          $("#MyTableData,#MyTableData1").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              search: "Chercher :",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
      });
    },
    // getFranchise(){
    //   this.isLoading = true;
    // axios
    //   .get(lien + `/api/fourn_package/${this.$route.params.id}`)
    //   .then((res) => {
   
    //     this.franchiseFournisseur = res.data.data;
         
    //     this.isLoading = false;
    //     setTimeout(function () {
    //       $("#MyTableData1").DataTable({
    //         pagingType: "full_numbers",
    //         pageLength: 10,
    //         processing: true,
    //         order: [],
    //         language: {
    //           décimal: "",
    //           emptyTable: "Aucune donnée disponible dans le tableau",
    //           infoEmpty: "Showing 0 to 0 of 0 entries",
    //           info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
    //           infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
    //           infoPostFix: "",
    //           thousands: ",",
    //           lengthMenu: "Afficher les entrées du _MENU_",
    //           loadingRecords: "Loading...",
    //           processing: "Processing...",
    //           search: "Chercher :",
    //           stateSave: true,
    //           zeroRecords: "Aucun enregistrement correspondant trouvé",
    //           paginate: {
    //             first: "Premier",
    //             last: "Dernier",
    //             next: "Suivant",
    //             previous: "Précédent",
    //           },
    //           aria: {
    //             sortAscending: ": activate to sort column ascending",
    //             sortDescending: ": activate to sort column descending",
    //           },
    //         },
    //       });
    //     }, 10);
    //   });
    // }
  },
  created() {
    this.getPackages()
    this.getUser();
    // this.getFranchise()
  },
};
</script>
<style scoped>
.spinner{
  background-color:rgb(27, 27, 27) !important;
  width:45px;
  height:45px;
  padding:.5em 0 0 0 ;
  border-radius:5px;
}
.spinnerWindow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 101, 101, 0.295);
  z-index: 99;
  display: flex;
  place-items: center;
  justify-content: center;
}
select {
  padding: 0.589em !important;
  border-radius: 0.25rem;
}
select:active {
  border: 1px solid rgb(134, 134, 134);
}
.my_footer{
position:relative;
width:100%;
bottom:-20em;
margin-left: 0 !important;
}
.btn-envoyer{
background: rgb(231, 202, 15) !important;
color:black !important;
border:3px solid black !important;
}
input,select{ 
  border: 1px solid black !important;
}
.form-group{ 
  text-align: left !important;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie .card{
  width:70%;
  padding:1em 0;
}
.icon {
  position: absolute;
  left: 1em;
  top: 0;
}
.table {
  border: thin solid rgba(139, 139, 139, 0.63) !important;
}
th,
td {
  border: thin solid rgba(141, 140, 140, 0.692) !important;
}
.my_footer {
  position: relative;
  width: 100%;
  bottom: -15em;
  margin-left: 0 !important;
}
.user {
  text-align: left;
}
input,
select {
  border: 1px solid black !important;
}
.form-group {
  text-align: left !important;
}
label {
  font-weight: bold !important;
}
</style>

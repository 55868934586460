import { createStore } from "vuex";

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    token: JSON.parse(localStorage.getItem("token")),
    address: false,
    alarmNumberTransaction: 0,
    alarmNumberRapportVente:0
  },
  mutations: {
    CHANGE_ALARM_TRANSACTIONS(state) {
      state.alarmNumberTransaction = state.alarmNumberTransaction-1;
    },
    CHANGE_ALARM_RAPPORT(state) {
      state.alarmNumberRapportVente = state.alarmNumberRapportVente-1;
    },
  },
  actions: {
    
  },
  modules: {},
});

<template>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    <div class="confirmation_modifie text-dark" v-if="windowForSendRapport && this.package">
      <div class="card">
        <div class="form-body">
          <form  @submit.prevent="send_rapport">
         
          <div class="card-body">
            <h1>Rapport de vente</h1>
           
            <div v-for="(item,index) in this.vente" :key="index">
              <table class="w-100">
                <tr>
                  <th>Date d'enregistrement</th>
                  <th>Produit</th>
                  <th>Stock initial</th>
                  <th>Quantité vendue</th>
                  <th>Stock restant</th>
                  <th>Prix unitaire (Fcfa)</th>
                  <th>Montant vendu (Fcfa)</th>
                  <th>Marge</th>
                  <th>Montant restant (Fcfa)</th>
                 
                </tr>
                <tbody>
                  <tr  v-for="(vente,index) in item.rapports" :key="index">
                    <td>{{new Date(vente.created_at).toLocaleDateString("fr")}}

                    </td>
                    <td>{{item.package.libelle}}</td>
                    <td>{{item.package.nb_products}}</td>
                    <td>{{moneyFormat.format(this.calculateProductSell(item.rapports))}}</td>
                    <td>{{moneyFormat.format(item.package.nb_products - this.calculateProductSell(item.rapports))}}</td>
                    <td>{{moneyFormat.format(item.package.cout_vente)}}</td>
                    <td>{{moneyFormat.format(item.package.cout_vente * this.calculateProductSell(item.rapports))}}</td>
                    <td>{{moneyFormat.format(item.package.gain_par_piece* this.calculateProductSell(item.rapports))}}</td>
                    <td>
                     
                    </td>
                  </tr>

                  <tr>
                    <td colspan="3">TOTAL GENERAL</td>
  
                    <td>{{this.calculateProductSell(item.rapports)}}</td>
                    <td>{{moneyFormat.format(item.package.nb_products - this.calculateProductSell(item.rapports))}}</td>
                    <td></td>
                    <td >
                      {{moneyFormat.format(this.calculateSum(item.rapports))}}
                      
                    </td>
                    <td>{{moneyFormat.format(item.package.gain_par_piece* this.calculateProductSell(item.rapports))}}</td>
                   
                    <td> {{moneyFormat.format(this.calculateSum(item.rapports)-(item.package.gain_par_piece* this.calculateProductSell(item.rapports)))}}</td>
                  </tr>
                 
                </tbody>
              </table>

              <div class="card-body">
                <button
                  type="submit"
                  class="btn text-white mx-3 btn-envoyer"
                  @click.prevent="validerRapport(item.id)"
                  v-if="item.rapports[0].valid == 0"
                 >
                  <div class="spinner-border text-dark" role="status" v-if="spinner">
            <span class="visually-hidden">Loading...</span>
          </div>
                  Approuver
                </button>
                <h1 class="badge bg-success success-rapport"
               v-else>Rapport approuvé</h1>
              
            </div>

            </div>
           
        
      
          </div>      
            <div class="card-body">
              <button @click="windowForSendRapport = !windowForSendRapport" type="button" class="btn btn-dark">
                Annuler
              </button>
          </div>
          </form>
        </div>
      </div>
    </div>

   
    <div class="page-wrapper">
      <div class="container-fluid position-relative">
        <div class="row page-titles">
          <div class="col-md-5 align-self-center"></div>
          <div class="col-md-7 align-self-center text-end">
            <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb justify-content-end">
                <li class="fw-bold h3">
                  <span
                    >Rapports de vente
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="icon">
          <a href="javascript:void(0)" class="back h4" @click="$router.go(-1)"
            ><box-icon name="left-arrow-alt" animation="tada"></box-icon
            >Retour</a
          >
        </div>
      </div>
      <div class="row container-fluid">
        <div class="col-md-12">
        
        
  
          <div>
         
            <table
              id="MyTableData"
              class="table"
            >
              <thead>
                <tr>
                  <th class="bg-light">Fournisseurs</th>
                  <th class="bg-light">Packages</th>
                  <th class="bg-light">Nombre de pièces vendues</th>
                  <th class="bg-light">Prix total de vente(Fcfa)</th>
                  <th class="bg-light">Statut</th>
                  <th class="bg-light">Détail</th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="key in Object.keys(franchise_fournisseur)" :key="key">
                  
                  <td>
                 
                    {{ `${franchise_fournisseur[key][0].fournisseur.nom } ${franchise_fournisseur[key][0].fournisseur.prenoms}` }}
                  </td>
                  <td>
                    {{ key }}
                   
                  </td>
                
                  <td>
                    {{ this.moneyFormat.format(franchise_fournisseur[key][0].package.nb_products) }}
                  </td>
                  <td>
                   {{
                      this.moneyFormat.format(
                        franchise_fournisseur[key][0].cout_total
                      )
                    }} 
                 
                  </td>
                  <td>
         
                     <span class="text-success" v-if="franchise_fournisseur[key][0].rapports[0].valid == 1">vérifier</span>
                     <span class="text-danger" v-else>En attente de verification</span>
                   </td>
                
                  <td>
                    <button class="bg-warning"
                    @click="showWindowForSendRapport(key)">Rapport <i class="bi-eye bi"></i></button>
                   
                  </td>
  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  <script>
  
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import Swal from "sweetalert2";
  import { lien } from "/src/assets/api.js";
  import $ from "jquery";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import axios from "axios";
  export default {
    name: "franchise",
    components: {
      
      Loading,
    },
    data() {
      return {
        sumProduct:"",
        franchiseFournisseur:[],
        packages_fournisseur: [],
        franchise_fournisseur:[],
        user: null,
        vente:[],
        moneyFormat: new Intl.NumberFormat("de-DE"),
        Mysolde: null,
        windowForSendRapport:false,
        nombreTotalDePiece:"",
        idPackage:"",
        package:null,
        proofPieces:"",
        fullPage: "",
        spinner:false,
      };
    },
    methods: {
     
      validerRapport(idVente){
        this.spinner = true
        // console.log(idVente)
      let data = {
        valid:1
      }
      
      axios.put(lien+"/api/rapports/"+idVente,data)
         .then(reponse =>{
           //console.log("sendRapport",reponse.data);
     
          if(reponse.data.status == 'true' || reponse.data.status === true){
               Swal.fire({
                text: "Validé",
                icon: "success",
                showConfirmButton: true,
              
              })
              this.windowForSendRapport = !this.windowForSendRapport
              this.$store.commit("CHANGE_ALARM_RAPPORT")
          }
          if(reponse.data.status === false){
            Swal.fire({
                text: reponse.data.message,
                icon: "info",
                showConfirmButton: true,
               
              })
              this.spinner = false
          }
      })
      .catch(error=>{
          console.log(error);
             Swal.fire({
                text: "Contactez votre service informatique",
                icon: "info",
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
              });
              this.spinner = false
      })
      },
     
     calculateSum(tableau){
   
       let compteur = 0;
       let sumProductSell = 0; 
       while(compteur < tableau.length){
       
        //console.log(tableau[compteur].cout)
        sumProductSell += Number(tableau[compteur].cout)
        compteur = compteur+1;
       }
       return  sumProductSell;
      },
      calculateProductSell(tableau){
   
   let compteur = 0;
   let sumProductSell = 0; 
   while(compteur < tableau.length){
    //console.log(tableau[compteur].produits_vendus)
    sumProductSell += Number(tableau[compteur].produits_vendus)
    compteur = compteur+1;
   }
   return  sumProductSell;
  },
      helpMeBoucle(tableau){
       let compteur = 0;
      let rapport =[];
       while(compteur < tableau.length){
         rapport = tableau[compteur].rapports
       
        compteur = compteur+1;
       }
       return rapport;
      },
      boucleInObjet(object){
        for (let prop in object) {
         
          let proprieteAndValue = {
            value:object[prop],
            key:prop
          }
          return proprieteAndValue;    
}

      },
      showWindowForSendRapport(index){
        //console.log(index)
        
        for (let prop in this.franchise_fournisseur) {
         
          if(index === prop){
            //console.log(this.franchise_fournisseur[prop])
        
            this.package = this.helpMeBoucle(this.franchise_fournisseur[prop])
            this.vente = this.franchise_fournisseur[prop]
            //console.log("this.package",this.package)

            this.sumProduct =  this.calculateSum(this.package)

            //console.log("this.sumProduct",this.sumProduct)
            
          }
   
 
}

        //console.log("this.package",this.package)
            //console.log("this.sumProduct",this.sumProduct)
        this.idPackage = index
    
        this.windowForSendRapport = !this.windowForSendRapport
      },
   
  
      addDaysToDate(date, days) {
        const res = new Date(date);
        res.setDate(res.getDate() + days);
        return res;
      },
      getRapportFranchise(){
        this.isLoading = true;
      axios
        .get(lien + `/api/rapports`)
        .then((res) => {
            //console.log("FRANCHISE",res.data)
            this.franchise_fournisseur  = res.data.data
            this.$store.state.alarmNumberRapportVente = res.data.data.length
            
          this.isLoading = false;
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        });
      },
  
    },
    created() {
      this.getRapportFranchise()

    
    },
  };
  </script>
  <style scoped>
  .success-rapport{
 width: 100%;
 font-size: 1.5em !important;
 background-color:rgba(127, 209, 127, 0.841)!important;

  }
  .spinner{
    background-color:rgb(27, 27, 27) !important;
    width:45px;
    height:45px;
    padding:.5em 0 0 0 ;
    border-radius:5px;
  }
  .spinnerWindow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(103, 101, 101, 0.295);
    z-index: 99;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  select {
    padding: 0.589em !important;
    border-radius: 0.25rem;
  }
  select:active {
    border: 1px solid rgb(134, 134, 134);
  }
 
  .btn-envoyer{
  background: rgb(231, 202, 15) !important;
  color:black !important;
  border:3px solid black !important;
  }
  input,select{ 
    border: 1px solid black !important;
  }
  .form-group{ 
    text-align: left !important;
  }
  .confirmation_modifie {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.255);
    display: flex;
    justify-content: center;
    place-items: center;
  }
  .confirmation_modifie .card{
    width:90%;
    padding:1em 0;
    border-radius:10px;
  }
  .icon {
    position: absolute;
    left: 1em;
    top: 0;
  }
  th,
  td,.table {
    border: thin solid rgba(139, 139, 139, 0.63) !important;
  }
   
  .my_footer {
    position: relative;
    width: 100%;
    bottom: -15em;
    margin-left: 0 !important;
  }
  .user {
    text-align: left;
  }


 
  </style>
  
<template>
  <div class="modify-profile" v-show="profile">
    <div class="form-body" v-if="user !== null">
      <h1>
        Modifier son Profil <box-icon name="pencil" animation="tada"></box-icon>
      </h1>
      <div class="card-body">
        <div class="row pt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Nom</label>
              <input
                type="text"
                id="firstName"
                class="form-control"
                placeholder="Nom"
                v-model="user.nom"
                :disabled="user.tab.includes('admin') ? false:true"
              />
            </div>
          </div>
          <!--/span-->
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Prénoms</label>
              <input
                type="text"
                class="form-control form-control-danger"
                placeholder="Prénoms"
                v-model="user.prenoms"
                :disabled="user.tab.includes('admin') ? false:true"
              />
            </div>
          </div>
          <!--/span-->
        </div>
        <!--/row-->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Télephone</label>
              <input
                type="text"
                class="form-control"
                placeholder="0704565676"
                required
                v-model="user.phone"
              />
            </div>
          </div>
          <!--/span-->
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">email</label>
              <input
                type="email"
                class="form-control"
                placeholder="ex:adjobi23@gmail.com"
                required
                v-model="user.email"
                :disabled="user.tab.includes('admin') ? false:true"
              />
            </div>
          </div>
          <!--/span-->
        </div>
        <!--/row-->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">lieu d'habitation</label>
              <input
                type="text"
                class="form-control"
                placeholder="ex:yopougon"
                v-model="user.lieu_habitation"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Mot de passe</label>
              <div class="position-relative">
                <input
                type="password"
                class="form-control"
                id="password"
                placeholder="******"
                v-model="password"
              />
              <i class="bi bi-eye position-absolute" v-show="tooglePassword" @click="seeMyPassword"></i>
              <i class="bi bi-eye-slash position-absolute" v-show="!tooglePassword" @click="seeMyPassword"></i>
              </div>
             
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Photo</label>
              <input
                type="file"
                class="form-control"
                @change.prevent="handleMediasPhotoProfil"
              />
            </div>
          </div>
          <!--/span-->
        </div>
        <!--/row-->
      </div>
      <div class="form-actions">
        <div class="card-body">
          <button
            type="submit"
            @click.prevent="modify_profil_user"
            class="btn btn-lg mx-2 btn-primary text-white"
          >
          <div class="spinner-border text-dark" role="status" v-if="spinner">
            <span class="visually-hidden">Loading...</span>
          </div>
           <span v-else>Modifier</span>
          </button>
          <button
            @click="modify_my_profil"
            type="button"
            class="btn btn-dark btn-lg"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <box-icon
      name="menu"
      class="menubarre"
      id="box-menu"
      @click="toogle_menu"
    ></box-icon>
    <aside class="left-sidebar" id="sidebar_menu">
      <img
        src="/assets/images/invest.jpg" 
        alt="homepage"
        class="w-75 bg-light img"
      />

      <!-- Sidebar scroll-->
      <div class="scroll-sidebar">
        <!-- User Profile-->
        <div class="user-profile">
          <div class="user-pro-body">
            <div class="profile img-circle">
              <img :src="'https://backend.invest-ci.com/public/'+this.$store.state.user.picture" :alt="user.picture">
            </div>

            <div class="dropdown">
              <a
                v-if="user !== null"
                href="javascript:void(0)"
                class="dropdown-toggle u-dropdown link hide-menu"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                >{{ this.user.nom }} {{ this.user.prenoms }}
                <span class="caret"></span>
              </a>
              <div class="dropdown-menu animated flipInY">
                <!-- text-->
                <a
                  href="javascript:void(0)"
                  @click="profile = !this.profile"
                  class="dropdown-item profil"
                >
                  <box-icon type="solid" name="face"></box-icon> Profil
                </a>
                <router-link
                  to="/solde"
                  class="dropdown-item profil"
                  v-if="seeSolde"
                >
                  <box-icon name="credit-card"></box-icon>Solde
                </router-link>

                <a
                  href="pages-login.html"
                  class="dropdown-item profil"
                  @click.prevent="Deconnexion"
                >
                  <box-icon name="log-out" animation="tada"></box-icon>
                  Déconnexion</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
          <ul id="sidebarnav">
            <li class="my-3">
              <router-link class="waves-effect waves-dark" to="/dashboard">
                <em class="bi bi-person-rolodex px-2"></em>
                <span class="hide-menu text-dark h4"> Tableau de bord </span>
              </router-link>
            </li>
            <li class="my-3">
              <a
                href="javascript:void(0)"
                @click="showPackages"
                v-if="showPanel"
              >
                <em class="bi bi-box-seam px-2"></em>
                <span class="hide-menu h4">Catégories</span>
                <em
                  class="bi bi-chevron-down position-absolute"
                  id="arrow-bas"
                ></em>
              </a>
              <ul v-show="sub_packages">
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/seepackage"
                    >Voir les catégories</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/types-packages-utilise"
                    >Les catégories(quantités)
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="my-3">
              <a
                class="waves-dark"
                href="javascript:void(0)"
                @click="show_packagess"
              >
                <em class="bi bi-box px-2"></em>
                <span class="hide-menu h4">Packages</span>
                <em
                  class="bi bi-chevron-down position-absolute"
                  id="arrow_bas_three"
                ></em>
              </a>
              <ul v-show="sub_packagess">
                <li v-if="showcreatedPackage">
                  <router-link class="px-0 a" to="/package">
                    Enregistrer</router-link
                  >
                </li>
                <li v-if="showcreatedPackage">
                  <router-link class="px-0 a" to="/voirMesPackage"
                    >Liste</router-link
                  >
                </li>
                <li v-if="showcreatedPackage">
                  <router-link class="px-0 a" to="/packages-souscription"
                    >Achetés</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/voirpackages"
                    >Packages</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/voirpackagespublish"
                    >Publiés</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/voirpackagesnotpublish"
                    > En cours de traitement</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/articles-rejetes"
                    > Rejetés</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="my-3">
              <a href="javascript:void(0)" @click="showUser" v-if="showPanel">
                <em class="bi bi-person px-2"></em>
                <span class="hide-menu h4">Utilisateurs</span>
                <i
                  class="bi bi-chevron-down position-absolute"
                  id="arrow-bas_two"
                ></i>
              </a>
              <ul v-show="sub_user">
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/users"
                    >Créer un utilisateur</router-link
                  >
                </li>
              
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/listusers"
                    >Liste des utilisateurs</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="my-3" v-if="showcreatedPackage">
              <a class="waves-dark" @click.prevent="show_transaction">
                <em class="bi bi-filter-square px-2"></em>
                <span class="hide-menu h4">Transactions</span>
                <em
                  class="bi bi-chevron-down position-absolute"
                  id="arrow_Paiement"
                ></em>
              </a>
              <ul v-show="sub_Paiement">
                <li>
                  <router-link class="px-0 a" to="/sendBordereau"
                    >Envoyer</router-link
                  >
                </li>
                <li>
                  <router-link class="px-0 a" to="/transactions"
                    >Liste</router-link
                  >
                </li>
                
              </ul>
            </li>
            <li class="my-3 conteneur-alarm">
              <a
                href="javascript:void(0)"
                @click="showTransactions"
                v-if="showPanel"
              >
              <span class="badge-alarm" 
              v-show="this.$store.state.alarmNumberTransaction
              ">
              {{this.$store.state.alarmNumberTransaction}}
            </span>
                <em class="bi bi-box-seam px-2"></em>
                <span class="hide-menu h4">Transactions</span>
                <em
                  class="bi bi-chevron-down position-absolute"
                  id="arrow-versement"
                ></em>
              </a>
              <ul v-show="sub_transactions">
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/versement"
                    >Transactions en attentes</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/moyens"
                    >Enregistrer un moyen de paiement</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/moyens_paiement"
                    >Liste des moyens de paiement</router-link
                  >
                </li>
                <li v-if="showPanel">
                  <router-link class="px-0 a" to="/transactions-approuve"
                    >Historique des transactions</router-link
                  >
                </li>
               
              </ul>
            </li>
          
            <li class="my-3">
              <router-link
                class="waves-dark"
                to="/fournisseurs"
               
                v-if="showPanel"
              >
                <em class="bi bi-people px-2"></em>
                <span class="hide-menu h4">Fournisseurs</span>
                
              </router-link>
            
            </li>
            <li class="my-3">
              <router-link
                class="waves-dark"
                to="/investisseurs"
              
                v-if="showPanel"
              >
                <i class="bi bi-people px-2"></i>
                <span class="hide-menu h4">Investisseurs</span>
              
              </router-link>
            
            </li>
            
            <li class="my-3">
              <router-link
                class="waves-dark"
               
                to="/souscription"
                v-if="showPanel"
              >
                <em class="bi bi-filter-square px-2"></em>
                <span class="hide-menu h4">Acheteurs</span>
               
              </router-link>
              
            </li>
            <li class="my-3" v-if="showcreatedPackage">
             
              <a class="waves-dark" @click="show_vente">
                <em class="bi bi-filter-square px-2"></em>
                <span class="hide-menu h4">Rapport de ventes  </span>
                <em
                  class="bi bi-chevron-down position-absolute"
                  id="arrow_bas_six"
                ></em>
              </a>
              <ul v-show="sub_vente">
                <li>
                  <router-link class="px-0 a" to="/saveRapportventes"
                    >Enregistrer</router-link
                  >
                </li>
                <li>
                  <router-link class="px-0 a" to="/list-rapport-ventes"
                    >Liste</router-link
                  >
                </li>
              </ul>
            </li>
           
            <!-- <n-collapse arrow-placement="right" v-if="showcreatedPackage">
              <n-collapse-item  title="Paiement" name="1">
                <div>
                  <ul>
                  <li>
                    <router-link class="px-0 a" to="/sendBordereau"
                      >Envoyer un bordereau</router-link
                    >
                  </li>
                 
                </ul>
                </div>
              </n-collapse-item>
            </n-collapse> -->
            
            <li class="my-3" v-if="showPanel">
              <router-link class="waves-dark"
              to="/list-rapport-ventes">
                <em class="bi bi-filter-square px-2"></em>
                <span class="hide-menu h4">Ventes</span>
              
              </router-link>
             
            </li>
            <li class="my-3 conteneur-alarm" v-if="this.user.tab.includes('admin')" >
              <router-link
                class="waves-dark"
                to="/rapport_franchise"
               
         
              >
              <span class="badge-alarm" 
              v-show="this.$store.state.alarmNumberRapportVente
              ">
              {{this.$store.state.alarmNumberRapportVente}}
            </span>
                <em class="bi bi-box px-2"></em>
               
                   <span class="hide-menu h4"  >
                   Rapports de vente </span> 
              </router-link>
             
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
</template>
<script>
// import store from  '@/store'
import Swal from "sweetalert2";
import axios from "axios";
import { lien } from "/src/assets/api.js";
export default {
  name: "Menu",
  data() {
    return {
      addressUrl:false,
      tooglePassword:true,
      sub_vente: false,
      sub_scribe: false,
      sub_fournisseur: false,
      sub_packages: false,
      sub_user: false,
      sub_transactions:false,
      sub_invest: false,
      spinner:false,
      sub_Paiement:false,
      user: this.$store.state.user,
      userCompte: {
        nom: null,
        prenoms: null,
        email: null,
        phone: null,
        lieu_habitation: null,
        pass: null,
      },
      password:'',
      profile: false,
      sub_packagess: false,
      admissiblite: null,
      hideFournisseur: true,
      showPanel: true,
      showcreatedPackage: true,
      seeSolde:true,
      el: null,
      pictureProfil:"",
    };
  },
  methods: {
    seeMyPassword() {
      let password = document.getElementById("password");
      password.type != "text"
        ? password.setAttribute("type", "text")
        : password.setAttribute("type", "password");
     this.tooglePassword = !this.tooglePassword
    
    },
    handleMediasPhotoProfil(files) {
    
      this.pictureProfil = files.target.files[0];
      //console.log("Photo de profil", files.target.files[0]);
    },
    Deconnexion() {
      axios
        .get(lien + "/api/auth/logout", {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((reponse) => {
          //console.log(reponse);
          if (reponse) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.$router.push("/");
            this.$store.state.user = null;
            this.$store.state.token = null;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Déconnecte",
            });
          }
        });
    },
    
    showTransactions(){
      let arrow = document.getElementById("arrow-versement");
      this.sub_transactions = !this.sub_transactions;
      if (this.sub_transactions === true) {
        arrow.style.transform = "rotate(180deg)";
      }
      if (!this.sub_transactions) {
        arrow.style.transform = "rotate(0deg)";
      }
    },
    showPackages() {
      let arrow = document.getElementById("arrow-bas");
      this.sub_packages = !this.sub_packages;
      if (this.sub_packages === true) {
        arrow.style.transform = "rotate(180deg)";
      }
      if (!this.sub_packages) {
        arrow.style.transform = "rotate(0deg)";
      }
    },
    showUser() {

      let fleche = document.getElementById("arrow-bas_two");
      this.sub_user = !this.sub_user;
      if (this.sub_user === true) {
        fleche.style.transform = "rotate(180deg)";
      }
      if (!this.sub_user) {
        fleche.style.transform = "rotate(0deg)";
      }
    
    },
    show_packagess() {
    
      let arrow_Bas = document.getElementById("arrow_bas_three");
      this.sub_packagess = !this.sub_packagess;
      if (this.sub_packagess === true) {
        arrow_Bas.style.transform = "rotate(180deg)";
      } 
      if (this.sub_packagess === false) {
        arrow_Bas.style.transform = "rotate(0deg)";
      }
    },
    show_fourni() {
     
      let arrow_Bas_four = document.getElementById("arrow_bas_four");
      this.sub_fournisseur = !this.sub_fournisseur;
      if (this.sub_fournisseur === true) {
        arrow_Bas_four.style.transform = "rotate(180deg)";
      }
      if (this.sub_fournisseur === false) {
        arrow_Bas_four.style.transform = "rotate(0deg)";
      }
    
    },
    show_scribe() {
      
      let arrow_bas_five = document.getElementById("arrow_bas_five");
      this.sub_scribe = !this.sub_scribe;
      if (this.sub_scribe === true) {
        arrow_bas_five.style.transform = "rotate(180deg)";
      }
      if (this.sub_scribe === false) {
        arrow_bas_five.style.transform = "rotate(0deg)";
      }
      
    },
    show_transaction(){
      //console.log("err")
      this.sub_Paiement = !this.sub_Paiement;
      let arrow_Paiement = document.getElementById("arrow_Paiement");
  
      if (this.sub_Paiement === true) {
        arrow_Paiement.style.transform = "rotate(180deg)";
      }
      if (this.sub_Paiement === false) {
        arrow_Paiement.style.transform = "rotate(0deg)";
      }
     
    },
    show_vente() {

      let arrowVente = document.getElementById("arrow_bas_six");
      this.sub_vente = !this.sub_vente;
      if (this.sub_vente === true) {
        arrowVente.style.transform = "rotate(180deg)";
      }
      if (this.sub_vente === false) {
        arrowVente.style.transform = "rotate(0deg)";
      }
     
    },
    show_invest() {

      let arrow_bas_invest = document.getElementById("arrow_bas_invest");
      this.sub_invest = !this.sub_invest;
      if (this.sub_invest === true) {
        arrow_bas_invest.style.transform = "rotate(180deg)";
      }
      if (this.sub_invest === false) {
        arrow_bas_invest.style.transform = "rotate(0deg)";
      }
     
    },
    modify_my_profil() {
      this.profile = !this.profile;
    },
    toogle_menu() {
      let barre = document.getElementById("box-menu");
      let menubarre = document.getElementById("sidebar_menu");
      menubarre.classList.toggle("active");
      barre.classList.toggle("push");
    },
    modify_profil_user() {
    
this.spinner = true
        let data = new FormData();
     
      data.append("nom", this.user.nom);
      data.append("prenoms", this.user.prenoms);
      data.append("phone", this.user.phone);
      data.append("email", this.user.email);
      data.append("lieu_habitation", this.user.lieu_habitation);
      data.append("password",this.password);
      data.append("picture", this.pictureProfil); 
      axios
        .post(lien + "/api/profile",data,{
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((reponse) => {
          // console.log(reponse);
          if (reponse.data.status == "true") {
            Swal.fire({
              text: "Modification effectuée",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.spinner = false
            reponse.data.data.tab = this.user.tab
            // console.log("reponse.data.data.tab",reponse.data.data.tab)
            this.$store.state.user.picture = reponse.data.data.picture
            localStorage.setItem("user", JSON.stringify(reponse.data.data));
            this.profile = !this.profile;
          }
          if (reponse.data.status === false || reponse.data.status == 'false') {
            Swal.fire({
              text: "erreur",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.spinner = false
          
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: "Echec",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
          this.spinner = false
        });
    },
    getRole() {
      if (this.admissiblite.includes("fournisseur")) {
        this.showPanel = false;
        this.showcreatedPackage = true;
      }
      if (this.admissiblite.includes("admin")) {
        this.showPanel = true;
        this.showcreatedPackage = false;
      }
    },
    userAD() {
      if (this.$store.state.token !== null) {
        this.user = this.$store.state.user;
        this.admissiblite = this.$store.state.user.tab;
      }
      if (this.$store.state.token === null) {
        this.user = null;
      }
    },
    verifedWheel(){
      this.addressUrl = window.location.pathname == '/rapport_franchise' ? true:false;
      // console.log(window.location.pathname)
    }
  },
  computed: {
    char() {
      let nom = this.user.nom;
      let prenom = this.user.prenoms;
      let char = nom.charAt(0);
      let charPrenom = prenom.charAt(0);
      let Mychart = char + charPrenom;
      return Mychart.toUpperCase();
    },
  },
  created() {
   
    this.userAD();
    this.getRole();
   

  },
};
</script>
<style scoped>


.img-circle img{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

ul{
  padding-bottom:3em;
}
a{
  cursor:pointer;
}
aside * {
  color: white !important;
}
input,
select {
  border: 1px solid black !important;
}
label {
  text-align: left !important;
}
.dropdown-item {
  color: black !important;
}
.bi-box,
.bi-person,
.bi-filter-square,
.bi-person-rolodex,
.bi-box-seam,
.bi-people {
  color: rgb(236, 182, 4) !important;
}
.hide-menu{
  text-transform: capitalize;
}
.profile {
  width: 60px;
  height: 60px;
  background: rgba(152, 151, 151, 0.601);
  margin: 0 auto;
  line-height: 60px;
  border: 3px solid rgb(236, 182, 4);
  position: relative;
  z-index: 99999;
}

.img-person-circle {
  font-size: 2.5em;
}
.bi-chevron-down {
  right:0.5em;
}
#arrow-bas_two{
  right:0;
}
.modify-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.512);
  z-index: 99;
  display: flex;
  place-items: center;
  justify-content: center;
  font-weight: bold;
}
.my_form {
  position: absolute;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.left-sidebar {
  background: rgb(3, 3, 3) !important;
  overflow-y: auto;
  z-index: 90 !important;
}
.left-sidebar::-webkit-scrollbar-track {
  border: 1px solid rgb(191, 191, 191);
  padding: 2px 0;
  background-color: #d1d0d0;
}

.left-sidebar::-webkit-scrollbar {
  width: 8px;
}

.left-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 56px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(236, 182, 4);
  border: 1px solid rgb(175, 175, 175);
}
li {
  text-align: left;
}
li a {
  padding-left: 1em !important;
}
ul {
  padding-left: 0 !important;
}
.a:hover {
  background: rgb(206, 175, 2);
}

.h4 {
  padding: 0 !important;
}
.form-body {
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.277);
  padding: 2em 0;
  width: 70%;
  border-radius: 10px;
}
.profil {
  display: flex;
  align-items: center;
}
.btn-primary {
  background: rgb(231, 202, 15) !important;
  border: 3px solid black !important;
  color: black !important;
}
button:active {
  transform: translateY(-3px) scale(0.9);
}
.menubarre {
  font-size: 2.5em !important;
  position: fixed;
  top: 0.1em;
  left: 0;
  z-index: 99;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-left: 225px;
  cursor: pointer;
}
.router-link-exact-active {
  border-left: 5px solid #03a9f3;
}
#sidebar_menu.active {
  width: 70px;
}
.img {
  position: absolute;
  top: 1em;
  left: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  z-index: 999;
}
.char {
  position: relative;
  z-index: 99;
}

#box-menu {
  display: none;
}
.sidebar-nav {
  padding: 0 !important;
}

@media screen and (max-width: 1169px) {
  #box-menu {
    display: block;
  }

  #box-menu.push {
    margin-left: 200px !important;
    font-size: 2.5em !important;
    position: fixed;
    top: 0.2em;
    left: 0;
    z-index: 99;
    font-weight: bold;
    cursor: pointer;
  }
  #sidebar_menu.active {
    width: 200px;
  }
  .left-sidebar {
    overflow: scroll;
  }
  .menubarre {
    top: 0.2em;
    margin-left: 76px !important;
  }
}

@media screen and (max-width: 766px) {
  .menubarre {
    top: 0.3em;
    margin-left: 280px !important;
  }
}
</style>

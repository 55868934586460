<template>
    <div>
     <footer class="footer">
            © {{new Date().getFullYear()}} lce
        </footer>
    </div>
</template>
<script>
export default {
   name:"Footer",
}
</script>
<style scoped>
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'

import { library } from '@fortawesome/fontawesome-svg-core'
import 'boxicons'
import "bootstrap-icons/font/bootstrap-icons.css"
import axios from 'axios'
import naive from 'naive-ui'
import UploadMedias from "vue-upload-drop-medias"
import { faHatWizard,faCircleArrowDown } from '@fortawesome/free-solid-svg-icons'




library.add(faHatWizard,faCircleArrowDown)
axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.state.token


createApp(App)
.use(store)
.use(naive)
.use(router)
.component('UploadMedias', UploadMedias)
.component('v-select', vSelect)
.mount('#app')

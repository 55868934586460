<template>
    <div>
    <h1>Désole</h1>
    <span>
    désolé nous avons pas pu trouver la page démandée.
    </span>
    <a href="#" class="fw-bold badge bg-info p-2" @click.prevent="$router.go(-1)">Retour</a>
    </div>
</template>
<script>
export default {
    name:'NotFound'
}
</script>

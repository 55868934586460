<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span>Enregistrer un package</span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section class="spinnerWindow" v-show="spinner">
      <div class="spinner" >
        <div class="spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
     </section>

    <form @submit.prevent="createPackage">
      <div class="form-body">
        <div class="card-body">
          <div class="row pt-3">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Choisir la catégorie</label>
                <select
                  class="w-100 form-group types"
                  v-model="type_id"
                  id="type"
                  required
                >
                  <option value="" disabled>-----Catégorie-----</option>
                  <option
                    v-for="type in list_type_packages"
                    :key="type.id"
                    :value="type.id"
                    :label="type.libelle"
                  >
                    {{ type.libelle }}
                  </option>
                </select>
              </div>
            </div>

            <!--/span-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Nom du package</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="libelle"
                  pattern="[a-z_ ]*"
                  required
                />
                <span class="small text-danger" v-if="!this.libelle|| this.libelle"
                  >Le nom doit être en miniscule*</span
                >
              </div>
            </div>

            <!--/span-->
          </div>
          <!--/row-->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Prix d'achat par pièce (Fcfa)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ex:1200"
                  v-model.number="cout_acquisition"
                  pattern="^([0-9]*)$"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label"
                  >Prix de vente par pièces (Fcfa)</label
                >
                <input
                  type="text"
                  class="form-control form-control-danger"
                  placeholder="ex:3000"
                  v-model.number="cout_vente"
                  pattern="^([0-9]*)$"
                  required
                />
              </div>
            </div>
            <!--/span-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Nombre de Pièces (Quantité)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ex:200"
                  v-model.number="nb_products"
                  pattern="^([0-9]*)$"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="form-group">
                  <label class="form-label d-block">Franchise</label>
                  <label class="d-block my-3">
                    <input type="radio" value="1" v-model="isPackageFranchise"> 
                     Oui
                  </label>
                  <label class="d-block">
                    <input type="radio" value="0" v-model="isPackageFranchise">
                    Non
                  </label>
                </div>
          
              </div>
            </div>


            <div class="col-md-6" v-if="isPackageFranchise == '0'">
              <div class="form-group">
                <label class="form-label">Nombre de part</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ex:25"
                  v-model.number="nb_part"
                  pattern="^([0-9]*)$"
                  required
                />
              </div>
            </div>

            

            <div class="col-md-6" v-if="isPackageFranchise == '0'">
              <div class="form-group">
                <label class="form-label">Délai d'écoulement (Nbre de jour)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model.number="nb_jours"
                  pattern="^([0-9]*)$"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Revenu</label>
                <select
                  class="w-100 form-group types"
                  v-model="revenuTranche"
                  id="type"
                  required
                >
                  <option value="" disabled>-----Revenu-----</option>
                  <option
                    value="journalier"
                  >
                  Journalier
                  </option>
                  <option
                    value="mensuel"
                  >
                  Mensuel
                  </option>
                  <option
                    value="hebdomadaire"
                  >
                  Hebdomadaire
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label d-block">Image</label>
                <input type="file" ref="takePicture" @change="handleFileUpload">
              </div>
            </div>
            <!--/span-->
          </div>
        </div>
        <div class="form-actions">
          <div class="card-body">
            <button class="btn text-white mx-3 btn-envoyer" type="submit">
              Envoyer
            </button>
            <button @click="$router.go(-1)" type="button" class="btn btn-dark">
              Retour
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
import { lien } from "/src/assets/api.js";
export default {
  name: "Package",
  created() {
    this.user = this.$store.state.user;
    this.get_Package();
  },
  data() {
    return {
      isPackageFranchise:"0",
      takePicturePackage:"",
      cout_acquisition: null,
      cout_vente: null,
      nb_products: null,
      nb_jours: null,
      user: null,
      user_id: null,
      libelle: null,
      list_type_packages: null,
      type_id:"",
      libelleName: null,
      spinner:false,
      nb_part:"",
      revenuTranche:"",
    };
  },
  components: {
    // Header,
    // Menu,
    // Footer,
  },
  methods: {
    createPackage() {
      let prixVente = this.cout_vente;
      let prixAchat = this.cout_acquisition;

      if (prixVente < prixAchat || prixVente == prixAchat) {
        Swal.fire({
          text: "Le prix de vente doit etre supérieur au prix d'achat",
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      }else if(this.nb_products % this.nb_part == 0 || this.isPackageFranchise == "1"){
        this.launchCreatedPackage();
      }else{
        Swal.fire({
          text: "le nombre de lot choisi doit etre divisible par le nombre de produits.",
          icon: "info",
          showConfirmButton: true,
         
        });
      }
      
     
    },
    launchCreatedPackage() {
      this.spinner = true
      let valueTypeId = document.getElementById("type").value;
      let value_name = document.querySelector(".types");
      for (let i = 0; i < value_name.length; i++) {
        let element = value_name[i];
        if (element.value == valueTypeId) {
          this.libelleName = element.textContent;
        }
      }
      let data = new FormData();
      data.append("cout_acquisition", this.cout_acquisition)
      data.append("cout_vente",this.cout_vente)
      data.append("libelle" ,this.libelle)
      data.append(" nb_products" ,this.nb_products)
      data.append("nb_jours" ,this.nb_jours ? this.nb_jours:0)
      data.append("user_id" ,this.user.id)
      data.append("type_id" ,valueTypeId)
      data.append("income_type",this.revenuTranche)
      data.append("tranche" ,this.nb_part)
      data.append("franchise" ,this.isPackageFranchise)
      data.append("photo" ,this.takePicturePackage)
      axios
        .post(lien + "/api/packages", data)
        .then((reponse) => {
          // console.log("launchCreatedPackage", reponse.data.status);
          if (reponse.data.status === "true") {
            Swal.fire({
              text: "Package crée avec success",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.spinner = false
            setTimeout(() => {
              this.$router.push("/voirMesPackage");
            }, 1500);
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
              text: "Contactez votre service informatique",
              icon: "info",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          this.spinner = false
        });
    },
    get_Package() {
      axios
        .get(lien + "/api/types")
        .then((reponse) => {
          //console.log("POSTTYPEPACKAGE", reponse);
          this.list_type_packages = reponse.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleFileUpload(event) {
      this.takePicturePackage = event.target.files[0];
      // console.log(this.takePicturePackage);
    }
  },
};
</script>
<style scoped>
* {
  font-weight: bold !important;
}
.spinner{
  background-color:rgb(27, 27, 27) !important;
  width:45px;
  height:45px;
  padding:.5em 0 0 0 ;
  border-radius:5px;
}
.spinnerWindow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 101, 101, 0.295);
  z-index: 99;
  display: flex;
  place-items: center;
  justify-content: center;
}
.my_footer {
  position: relative;
  width: 100%;
  bottom: -9em;
  margin-left: 0 !important;
}
select {
  padding: 0.589em !important;
  border-radius: 0.25rem;
}
select:active {
  border: 1px solid rgb(134, 134, 134);
}
.btn-envoyer {
  background: rgb(231, 202, 15) !important;
  color: black !important;
  border: 3px solid black !important;
}
input,
select {
  border: 1px solid black !important;
}
.form-group {
  text-align: left !important;
}
input:invalid,
select:invalid {
  border: 1px solid red !important;
}
input:valid,
select:valid {
  border: 1px solid rgb(74, 164, 0) !important;
}
input[type="file"]{
  border: 1px solid rgba(0, 0, 0, 0) !important;
  width:100%;
padding:.5em 0;
}
</style>

<template>
<loading :active="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="fullPage"></loading>
  
  <div class="page-wrapper">
    <div class="container-fluid position-relative">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span v-if="utilisateur !== null">Modifier les informations de : <input type="text" v-model="utilisateur.nom"></span></li>
            </ol>
          </div>
        </div>
      </div>
      <div class="icon">
        <a href="javascript:void(0)" class="back h4" @click="$router.go(-1)"
          ><box-icon name="left-arrow-alt" animation="tada"></box-icon
        >Utilisateur</a>
      </div>
    </div>
    <section class="spinnerWindow" v-show="spinner">
      <div class="spinner" >
        <div class="spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
     </section>

    <form @submit.prevent="modify_profil_user" v-if="this.utilisateur">
      <div class="form-body">
        <div class="card-body">
          <div class="row pt-3">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Nom</label>
                <input
                  type="text"
                  id="firstName"
                  class="form-control"
                  placeholder="Nom"
                  v-model="utilisateur.nom"
                  required
                />
              </div>
            </div>
            <!--/span-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Prénoms</label>
                <input
                  type="text"
                  class="form-control form-control-danger"
                  placeholder="Prénoms"
                  v-model="utilisateur.prenoms"
                  required
                />
              </div>
            </div>
            <!--/span-->
          </div>
          <!--/row-->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Télephone</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="0704565676"
                  required
                  v-model="utilisateur.phone"
                />
              </div>
            </div>
            <!--/span-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="ex:adjobi23@gmail.com"
                  required
                  v-model="utilisateur.email"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Solde</label>
                <input
                  type="number"
                  class="form-control"
              
                
                  v-model="utilisateur.solde"
                />
              </div>
            </div>
           
            <div class="col-md-6" >
              <div class="form-group" v-if="this.utilisateur.registre_commerce !== 'null'">
                <label class="form-label">Registre de commerce</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="utilisateur.registre_commerce"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Numéro de compte bancaire</label>
                <input
                  type="text"
                  class="form-control"
                
                  v-model="utilisateur.bank_card"
                />
              </div>
            </div>
            <!--/span-->
          </div>
          <!--/row-->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Lieu d'habitation</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ex:yopougon"
                  v-model="utilisateur.lieu_habitation"
                
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Assigner un role</label>
                <v-select class="w-100" v-model="utilisateur.role" :searchable="true"
                :options="listRole" placeholder="choisir un role" multiple label="libelle">
                   <template>
                     <p>{{label}}</p>
                   </template>
                 </v-select>
               
              
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Mot de Passe</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="ex:******"
                  v-model="password"
               
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Pièce jointe</label>
                <input
                  type="file"
                  class="form-control"
                  @change.prevent="handleMedias"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Photo de profil</label>
                <input
                  type="file"
                  class="form-control"
                  @change.prevent="handleMediasPhotoProfil"
                />
              </div>
            </div>

          </div>
          
           
        </div>
      </div>
     <div class="mb-4">
      <button type="submit" class="btn btn-modify">
        Modifier
      </button>
      <button @click="$router.go(-1)" type="button" class="btn btn-dark mx-2">Annuler</button>
     </div>
    </form>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import 'vue-select/dist/vue-select.css';

import 'vue-loading-overlay/dist/vue-loading.css';
import { lien } from "/src/assets/api.js";
import axios from "axios";
export default {
  name: "Assignerrole",
  //++++ DATA ++++//
  data() {
    return {
      proofPieces:"",
      pictureProfil:"",
      listRoleFilter:[],
      user_id: this.$route.params.id,
      role_id: null,
      listRole: [],
      password:null,
      spinner:false,
      fullPage:null,
      utilisateur:{
        nom:"",
        prenoms:"",
        email:"",
        phone:"",
        lieu_habitation:"",
        role_id:"",
        solde:"",
        bank_card:"",
        registre_commerce:""
      },
      onCancel:null,
      profile:[],
      profil:null,
      prof:[],
        userCompte:{
        nom:null,
        prenoms:null,
        email:null,
        phone:null,
        lieu_habitation:null,
        role_id:null,
      },
    };
  },

  //++++ CREATED ++++//
  created() {
    this.get_role()
    this.get_info_user()
  },

  //++++ METHODS ++++//

  methods: {
    handleMediasPhotoProfil(files) {
      
      this.pictureProfil = files.target.files[0];
      
    },
    handleMedias(files) {
      
      this.proofPieces = files.target.files[0];
   
    },
   
    boucleTableau(tableau){
     let tableauRole = []
      tableau.forEach(item=>{
        tableauRole.push(item.id)
    })
    return tableauRole
    },
     modify_profil_user(){
  
       this.spinner = true
      //  console.log("utilisateur.role",this.utilisateur.role)
       let isFranchise = this.utilisateur.franchise == 1 ? 1:0;
       let tableauRole = this.boucleTableau(this.utilisateur.role)
       let data = new FormData();
      for (let i = 0; i < tableauRole.length; i++) {
        data.append("role_id[]",tableauRole[i]);
        }
      data.append("nom", this.utilisateur.nom);
      data.append('solde',this.utilisateur.solde)
      data.append("prenoms", this.utilisateur.prenoms);
      data.append("phone", this.utilisateur.phone);
      data.append("email", this.utilisateur.email);
      data.append("lieu_habitation", this.utilisateur.lieu_habitation);
      data.append("password",this.password);
      data.append("id",this.$route.params.id);
      data.append("registre_commerce", this.utilisateur.registre_commerce);
      data.append("identity", this.proofPieces);
      data.append("bank_card", this.utilisateur.bank_card);
      data.append("picture", this.pictureProfil); 
      data.append("franchise", isFranchise); 
       axios.post(lien+`/api/update`,data)
       .then(reponse=>{
         //console.log("RESPONSE MODIFY",reponse);
        
          if(reponse.data.status === true){
            Swal.fire({
              text:"Modification effectuée",
              icon: 'success',
              showConfirmButton: true,
            });
            this.spinner = false
            this.$router.push('/listusers')
        }
        if(reponse.data.status === 'false'){
            Swal.fire({
              text:reponse.data.message,
              icon: 'error',
              showConfirmButton: true,
             
            });
            this.spinner = false
        }
        this.spinner = false
       })
       .catch(error=>{
         console.log(error);
          Swal.fire({
              text:"Modification echouée",
              icon: 'error',
              showConfirmButton: true,
            });
            this.spinner = false
       })
    },
    getValue(){
      //console.log(this.role_id);
    },
    get_info_user(){
      this.isLoading = true
       axios.get(lien+"/api/users")
            .then(reponse=>{
               //console.log("LES USERS",reponse.data.data);
               this.list_users = reponse.data.data
               this.utilisateur = this.list_users.find(item => item.id == this.$route.params.id)
               this.profil = this.utilisateur.role
               this.profil.forEach((prof) => {
              this.profile.push(prof.id);
            })
              //  for (let index = 0; index < this.profil .length; index++) {
              //    const element = this.profil [index];
              //    this.prof.push(element.libelle)
              //  }
               //console.log("utilisateur",this.utilisateur);
                //console.log("Profil",this.prof);
               this.isLoading = false
            })
    },
    get_role(){
      this.isLoading = true
  axios.get(lien+"/api/roles")
      .then((reponse) => {
        //console.log("ROLES:", reponse.data.data);
        this.listRole = reponse.data.data;
        
        this.isLoading = false
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
};
</script>
<style scoped>
.spinner{
  background-color:rgb(27, 27, 27) !important;
  width:45px;
  height:45px;
  padding:.5em 0 0 0 ;
  border-radius:5px;
}
.spinnerWindow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 101, 101, 0.295);
  z-index: 99;
  display: flex;
  place-items: center;
  justify-content: center;
}
.icon {
  position: absolute;
  left: 1em;
  top: 0;
}
select {
  padding: 0.589em !important;
  border-radius: 0.25rem;
}
select:active {
  border: 1px solid rgb(134, 134, 134);
}
.btn-modify{
background: rgb(231, 202, 15) !important;
border:3px solid black !important;
}

.my_footer{
position:relative;
width:100%;
bottom:-7em;
margin-left: 0 !important;
}
input,select{ 
  border: 1px solid black !important;
}
.form-group{ 
  text-align: left !important;
}
label{
  font-weight: bold;
}
</style>
